.container {
    width: 60%;
}

.headerWrapper {
    z-index: 999;
    position: fixed;
    background-color: #fff;
    width: inherit;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 26px;
}

.title {
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000;
}

.closeIconWrapper {
    width: 43px;
    height: 43px;
    border-radius: 100%;
    background-color: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.contentWrapper {
    padding: 0px 26px;
}

.footerWrapper {
    z-index: 999;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px -4px 20px 0px #00000026;
    width: inherit;
}

.footer {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.infoText {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #6b7280;
}

.footerBtnWrapper {
    display: flex;
    gap: 10px;
}
