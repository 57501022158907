.inputLabel {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1d2939;
}

.inputWrapper {
    margin: 19px 24px;
}

.inputField {
    margin-top: 6px;
    padding: 8px 14px;
}

.inputField input {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 0px;
}

@media (min-width: 601px) {
    .form {
        width: 542px;
    }
}
