.selectWrapper {
    width: auto;
    margin: 15px 24px;
}

.inputLabel {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #1d2939;
}

.customSelect {
    padding-right: 20px;
}

.listItem:hover,
.listItem[aria-selected='true'],
.listItem[aria-selected='true']:hover {
    background-color: #eff8ff;
    font-weight: 500;
}

.listItem,
.listItem[aria-selected='true'] {
    display: block;
    max-width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
