.previewModalContainer {
    width: 90vw;
    height: 90vh;
}

@media only screen and (max-width: 768px) {
    .previewModalContainer {
        width: 100vw;
        height: 100%;
    }
}

.passCriteriaContainer {
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    background: #eaecf0;
    min-height: 214px;
    height: 100%;
    position: relative;

    .controlIconsWrapper {
        background: #000;
        border-radius: 3px;
        padding: 2px;
        color: #fff;
        z-index: 999;
    }

    .controlIconsWrapper:hover {
        background: #000;
    }

    .maxScreenControl {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .scaleControl {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        padding: 0;
    }
}

.controlIcons {
    width: 13px;
    height: 13px;
    color: inherit;
}

.maxControlIcon {
    width: 27px;
    height: 27px;
    color: inherit;
}

.fullWrapper {
    width: 100%;
    height: 100%;
}
.fixedWrapper {
    width: 100%;
    height: 320px;
}
