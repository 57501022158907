@font-face {
    font-family: 'Rubik';
    src: local('Rubik'), url(./asset/fonts/Rubik-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    src: local('Rubik'), url(./asset/fonts/Rubik-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    src: local('Rubik'), url(./asset/fonts/Rubik-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url(./asset/fonts/Inter-VariableFont.ttf);
}

:root {
    --font-rubik: 'Rubik', sans-serif;
    --mobile-top-bar-height: 50px;
}

body {
    margin: 0;
    font-family: Rubik, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Rubik, sans-serif;
}

#hubspot-messages-iframe-container .hs-shadow-container.active {
    background: none;
}
