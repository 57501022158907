.container {
    border: 1px solid #eaecf0;
    border-bottom: none;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    overflow: initial;
}

.tableHead {
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
}

.tableHeadCell {
    padding: 12px 0px 12px 24px;
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #475467;
    white-space: nowrap;
}

.tableBodyRow {
    border-bottom: 1px solid #eaecf0;
}

.tableBodyCell {
    padding: 24px 0px 24px 24px;
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #262626;
}

.topMostCell {
    padding-top: 90px;
}

.strongFont {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.deleteIcon {
    width: 20px;
    height: 20px;
    color: #4b5563;
    cursor: pointer;
}

.checkbox:hover {
    background-color: transparent;
}

.checked {
    color: #154683;
    svg {
        color: #154683;
    }
}

.checked:hover {
    background-color: transparent !important;
}

.selectionInfoRow {
    z-index: 3;
    position: fixed;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc((60% - 2 * 26px) - 7px);
    margin-bottom: 500px;
    border-bottom: 1px solid #eaecf0;
}

.selectionGreyBg {
    background-color: #eaecf0;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectionTextWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectionInfoCheckboxCell {
    padding: 12px 0px 12px 24px;
    border-bottom: none;
}

.selectionInfoCell {
    padding: 12px 0px 12px 24px;
    border-bottom: none;
    width: 100%;
}

.selectionInfoTextRight {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #667085;
    margin-right: 20px;
    text-align: right;
}

.selectionInfoTextCenterWrapper {
    display: flex;
    align-items: center;
}

.selectionInfoTextCenter {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #344054;
    margin: 0 30px;
    text-align: center;
}

.listingsCount {
    font-weight: 500;
    color: #344054;
    padding-right: 3px;
}

.selectAllBlue {
    font-weight: 500;
    color: #175cd3;
    cursor: pointer;
    font-size: 12px;
}

.selectAllBlue:hover {
    background-color: transparent;
}

.clearAllBtn {
    border-left: 1px solid #ddd;
    min-width: fit-content;
}
