.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
    gap: 40px;
}

.btnBase {
    width: 100%;
    height: 100%;
    z-index: 3;
}

.card {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px 0px #00000026;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 230px;
}

.cardLogo {
    display: flex;
    align-items: center;
}

.company_logo {
    height: 26px;
    width: auto;
    margin: 0px auto 19px;
}

.cardHeading {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding-left: 8px;
}

.cardDetail {
    position: relative;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    max-width: 294px;
    z-index: 1;
    margin-bottom: 10px;
}

.activeBadge {
    margin: 0 auto;
}

.maskedLogo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 744px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .integrationText_1 {
        font-size: 20px;
        line-height: 24px;
    }

    .integrationText_2 {
        margin-bottom: 45px;
    }

    .container {
        justify-content: center;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .guestyCard {
        padding: 8px 10px;
    }

    .card {
        border: 1px solid #e5e7eb;
        box-shadow: none;
    }
}
