.divider {
    background-color: #f0f0f0;
}

/* FormHeader.jsx styling */

.commonFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;

    .commonFormHeading {
        font-family: Rubik, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    .modalCloseBtn {
        padding: 0;
    }

    .modalCloseIcon {
        width: 24px;
        height: 24px;
        color: #262626;
    }

    .mobileFormHeading {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .modalCloseBtnMobile {
        width: 28px;
        height: 28px;
        padding: 5px;
        border-radius: 50%;
        background-color: #f3f4f6;
    }

    .modalCloseBtnMobile .modalCloseIcon {
        width: 18px;
        height: 18px;
    }

    @media only screen and (max-width: 480px) {
        .commonFormHeader {
            padding: 16px;
        }
    }
}

/* FormFooter.jsx styling */

.commonFormFooter {
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    position: relative;

    .commonFooterBtn {
        font-family: var(--font-rubik);
        padding: 7px 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #fff;
        border-radius: 4px;
        text-transform: none;
        box-shadow: none;
    }

    .commonFooterBtn:hover {
        background: #154683;
    }

    .secondaryBtn {
        background: #fff;
        border: 1px solid #bfbfbf;
        color: #171717;
    }

    .primaryBtn {
        background: #154683;
    }
    .primaryBtn.danger {
        background: red;
    }

    .primaryBtn:disabled {
        background: #8ea2ba;
        color: #fff;
    }

    .secondaryBtn:hover {
        background: #f9fafb;
    }

    @media only screen and (max-width: 600px) {
        .commonFooterBtn {
            padding: 8px 16px;
            flex-grow: 1;
            width: max-content;
            font-size: 14px;
            line-height: 21px;
        }
    }
}
