.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
    gap: 14px;
}

.icon {
    height: 59px;
    width: 59px;
    color: #9ca3af;
}

.text {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #9ca3af;
}
