.container {
    overflow-y: hidden;
}

.rightArrowIcon {
    width: 18px;
    height: 18px;
}

.scrollWrapper {
    height: calc(100% - 280px);
}
