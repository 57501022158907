.set {
    font-family: var(--font-rubik);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #111827;
}

.btnSetContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
    align-items: center;
    margin-bottom: 10px;
}

.commonBtn {
    font-family: var(--font-rubik);
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    box-shadow: none;
    color: #fff;
    padding: 12px 0px;
}

.commonBtn:hover {
    box-shadow: none;
}

.btnBox {
    padding: 5px;
    border-radius: 5px;
}

.btnBox:hover {
    background: #becdea;
}

.checkMarkIcon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.btnBoxSelected {
    background: #becdea;
}

.contained_button_1 {
    background: #154683;
    border-radius: 6px;
    text-transform: none;
}

.contained_button_1:hover {
    background: #154683;
}

.outlined_button_1 {
    color: #154683;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #154683;
    text-transform: none;
}

.outlined_button_1:hover {
    background: #ffffff;
}

.contained_button_2 {
    background: #1ba1db;
    border-radius: 15px;
    text-transform: none;
}

.contained_button_2:hover {
    background: #1ba1db;
}

.outlined_button_2 {
    color: #1ba1db;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #1ba1db;
    text-transform: none;
}

.outlined_button_2:hover {
    background: #ffffff;
}

.contained_button_3 {
    background: #154683;
    text-transform: none;
}

.contained_button_3:hover {
    background: #154683;
}

.outlined_button_3 {
    color: #154683;
    background: #ffffff;
    border: 1px solid #154683;
    text-transform: none;
}

.outlined_button_3:hover {
    background: #ffffff;
}

.contained_button_4 {
    background: #7b61ff;
    border-radius: 44px;
    text-transform: none;
}

.contained_button_4:hover {
    background: #7b61ff;
}

.outlined_button_4 {
    color: #7b61ff;
    background: #ffffff;
    border: 1px solid #7b61ff;
    border-radius: 44px;
    text-transform: none;
}

.outlined_button_4:hover {
    background: #ffffff;
}
