.label {
    font-family: var(--font-rubik);
    font-size: 14px;
    line-height: 24px;
    color: #252525;
}

.dropDownButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-family: var(--font-rubik);
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    margin-top: 4px;
    color: #000000;
}
