.configureBrandContainer {
    background-color: #d1e9ff;
    height: 100vh;
    overflow-y: auto;
}

.innerConfigureContainer {
    max-width: 1440px;
    margin: 0 auto;
}

.configureHeader {
    padding: 16px 24px;
}

.configureBrandLayout {
    margin-top: 20px;
    padding: 0 16px 50px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    height: auto;
}

.listBrandsContainer {
    grid-column: 1;
    width: 300px;
    max-height: 90vh;
}

.editBrandContainer {
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 20px;
    grid-column: 2 / 6;
    max-height: 90vh;
    overflow-y: auto;
}

.editBrandContainer::-webkit-scrollbar {
    width: 11px;
    background-color: #f3f4f6;
}

.editBrandContainer::-webkit-scrollbar-thumb {
    background-color: #9ca3af;
    border-radius: 10px;
}

.editBrandContainer::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
.editListingContainer {
    grid-column: 6;
    padding: 10px 16px;
    background-color: #f5faff;
    border-radius: 20px;
    min-width: 300px;
    max-height: 90vh;
    overflow-y: hidden;
}

.moveHereBtn {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #154683;
}

.plusIcon {
    height: 18px;
    width: 18px;
    color: #154683;
}

.listingSearch {
    background-color: #fff;
    border-radius: 20px;
}

.listingSearch fieldset {
    border: none !important;
}

.listingItem {
    background-color: #fff;
    border: none;
}

.listItemPlaceholder {
    background-color: #d1e9ff;
}
