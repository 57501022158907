.container p,
span {
    font-family: Rubik, sans-serif;
}

.container {
    height: 100%;
    overflow-y: hidden;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listingsCount {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000;
}

.headerBtn {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #0c1a68;
    text-decoration: underline;
}

.headerBtn:hover {
    background-color: transparent;
}

.searchInput {
    margin: 9px auto 0px;
    border-radius: 8px;
}

.searchInput input,
.searchInput input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #262626;
    padding: 9px 0px;
}

.searchIcon svg {
    width: 17px;
    height: 17px;
}

.searchIcon svg path {
    fill: #667085;
}

.listWrapper {
    max-width: 99%;
    max-height: 90%;
    overflow-y: auto;
    padding-right: 10px;
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
.listWrapper::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

.listWrapper::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 40px;
}

.listWrapper::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 40px;
}

.listItem {
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    background-color: #f9fafb;
    overflow: hidden;
    margin: 10px auto;
}

.listItem:hover,
.listItem:focus {
    cursor: default;
    border: 1px solid #53b1fd;
    background-color: #d1e9ff;
}

.listItemName {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #262626;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.listItemAddress {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #262626;
    margin-top: 6px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemPlaceholder {
    height: 60px;
    width: auto;
    border-radius: 10px;
    background-color: #f3f4f6;
    margin: 10px auto;
}

.tooltip {
    background-color: #fff;
    width: 250px;
    text-wrap: wrap;
}

.tooltipArrow {
    color: #fff;
}

.tooltipName,
.tooltipAddress {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: #262626;
}

.tooltipName {
    font-weight: 600;
}

.tooltipAddress {
    font-weight: 400;
    margin-top: 9px;
}
