.formHeading {
    font-family: var(--font-rubik);
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    margin-top: 15px;
}

.formSubHeading {
    font-family: var(--font-rubik);
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #475467;
    margin-top: 3px;
}

.divider {
    margin: 15px 0;
    background-color: #dddddd;
}

.form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.label {
    font-family: var(--font-rubik);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1d2939;
}

.formContainer .formBtn {
    font-family: var(--font-rubik);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    background: #0c1a68;
    padding: 8px 50px;
    width: max-content;
}

.formBottomLine {
    position: absolute;
    background-color: #f5fbff;
    padding: 12px 40px;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    gap: 4px;

    .bottomText,
    .cta {
        font-family: var(--font-rubik);
        font-size: 14px;
        line-height: 24px;
    }

    .bottomText {
        color: #4b5563;
    }

    .cta {
        text-decoration: none;
        font-weight: 500;
        color: #1570ef;
    }
}

@media only screen and (max-width: 600px) {
    .formContainer .formBtn {
        width: 100%;
    }

    .formBottomLine {
        padding: 10px 20px;
    }
}
