:root {
    --font-family: 'Rubik', sans-serif;
    --color-blue: #154683;
}
.modal {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
}
.paymentModalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    max-width: 28vw;
    border-radius: 12px;
    padding: 32px;
    outline: none;
    border: none;
}
.title {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 8px;
}
.modalText {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 26px;
    text-wrap: pretty;
    color: #101828;
}
.link {
    text-decoration: none;
    color: var(--color-blue);
    font-weight: 500;
}
.btn_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    gap: 12px;

    .btn {
        text-transform: none;
        font-family: var(--font-family);
        border: 1px solid var(--color-blue);
        font-weight: 500;
        padding: 6px 16px;
        border-radius: 4px;
    }

    .btn_primary {
        color: var(--color-blue);
    }
    .btn_secondary {
        background-color: var(--color-blue);
        color: #fff;
    }
}

@media only screen and (max-width: 820px) {
    .paymentModalContent {
        max-width: 60vw;
    }
}

@media only screen and (max-width: 600px) {
    .paymentModalContent {
        max-width: 90%;
        max-height: 90%;
        padding: 24px;
    }
    .title {
        font-size: 20px;
    }
    .modalText {
        font-size: 14px;
        line-height: 24px;
    }
    .btn_wrapper {
        margin-top: 10px;
    }
}
