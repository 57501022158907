.editBrandLayout {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    height: auto;
}

.editBrandContainer {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 20px;
    grid-column: 1 / 6;
    max-height: 90vh;
    overflow-y: auto;
}

.editBrandContainer::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

.editBrandContainer::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 40px;
}

.editBrandContainer::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 40px;
}

.listingContainer {
    background-color: #f5faff;
    padding: 16px 10px;
    border-radius: 20px;
    grid-column: 6;
    min-width: 300px;
    max-height: 90vh;
}
