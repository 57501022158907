.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 30px;
}

.logo {
    width: 154.84px;
    height: 44.06px;
    margin-bottom: 30px;
}

.connectionError {
    color: red;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    font-family: var(--font-rubik);
    margin-bottom: 10px;
    padding: 10px;
    width: 340px;
}

.alterIcon {
    height: 48px;
    width: 40px;
}
