.listBrandContainer {
    padding: 0 10px 10px;
    background: none;
    height: 100%;
    overflow-y: hidden;
}

.searchInput {
    border-radius: 20px;
    background-color: #f2f4f7;
}

.searchInput input,
.searchInput input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #98a2b3;
    padding: 10px 0px;
}

.searchInput input {
    color: #262626;
}

.searchIcon svg {
    width: 20px;
    height: 20px;
}

.searchIcon svg path {
    fill: #667085;
}

.addBrandButton {
    margin-top: 14px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #154683;
    color: #0c1a68;
    background: none;
    font-size: 12px;
    line-height: 18px;
}
.addBrandButton:hover {
    background: #f2f4f7;
}

.addIcon {
    width: 18px;
    height: 18px;
}

.allBrandContainer {
    margin-top: 14px;
    max-height: 100%;
    overflow-y: auto;
    padding-right: 10px;
}

.allBrandContainer::-webkit-scrollbar {
    width: 11px;
    background-color: #f3f4f6;
}

.allBrandContainer::-webkit-scrollbar-thumb {
    background-color: #9ca3af;
    border-radius: 10px;
}

.allBrandContainer::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.listItem,
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    margin-bottom: 14px;
    padding: 8px 18px;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
}

.listItemText {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: inherit;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.defaultText {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
