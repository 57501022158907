.heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #111827;
}

.subHeading {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #111827;
}

.noProduct {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 14px;
    line-height: 16px;
    color: #111827;
    text-align: center;
}

@media (max-width: 600px) {
    .noProduct {
        left: 35%;
    }
}

.switchBase {
    color: white !important;
}

.switchBase .Mui-checked + .MuiSwitch-track {
    background-color: #0dc671;
    opacity: 1;
}

.loaderAlignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
