.previewBox {
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    min-width: fit-content;
    max-width: 100%;
}

.mainSection {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.previewScreens {
    zoom: 0.4;
    -ms-zoom: 0.4;
}
