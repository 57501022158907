.editBrandWrapper {
    background: #d1e9ff;
}

.guestyActivePageWrapper {
    background: #f2f4f7;
}

@media screen and (max-width: 744px) and (min-width: 601px) {
    .guestyActivePageWrapper {
        padding-left: 26px;
        padding-right: 26px;
    }
}

.backIcon svg {
    width: 28px;
    height: 32px;
}

.backIcon svg path {
    fill: #2b2b2b;
}

.title {
    color: #111827;
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
}

.guestyMarketLink {
    color: #fff;
    text-decoration-color: grey;
}
