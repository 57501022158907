.editBrandScreenHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.backIconBox {
    padding: 0;
}

.backIcon {
    height: 40px;
    width: 40px;
    color: #000;
}

.title {
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 10px;
}

.saveIcon {
    height: 18px;
    width: 18px;
    color: #fff;
    margin-left: 4px;
}
