.rcp {
    border-radius: 0px;
    background-color: #fff;
}

.rcp-saturation {
    border-radius: 0px;
}

.rcp-section {
    gap: 20px;
}

.rcp-hue,
.rcp-alpha {
    height: 10px;
}

.rcp-hue-cursor,
.rcp-alpha-cursor {
    height: 14px;
    width: 14px;
}
