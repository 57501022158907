.colorContainer {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #b2ddff;
    border-radius: 6px;
    padding: 8px;
    margin-top: 8px;
}

.title {
    font-family: var(--font-rubik);
    font-size: 14px;
    font-weight: 400;
    color: #374151;
}

.colorPickerButton {
    all: unset;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.colorDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.colorCode {
    font-family: var(--font-rubik);
    color: #6b7280;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    text-transform: lowercase;
    letter-spacing: 2%;
}

.colorPreview {
    height: 18px;
    width: 21px;
    border-radius: 4px;
}

.divider {
    margin: 12px 0px;
}
