.brandHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.brandHeader .brandHeading {
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #000;
    margin-top: 8px;
    margin-bottom: 0px;
}

.brandHeading {
    max-width: inherit;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
}

.headingBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.editIcon {
    width: 18px;
    height: 18px;
    margin-left: 4px;
}

.workflowSection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 40px;
}

.previewSection {
    margin-top: 30px;
}

.previewSection .previewSectionHeading {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #475467;
    margin-bottom: 8px;
}

.defaultTag {
    color: #175cd3;
    background: #eff8ff;
}

.workflowPreviewBox {
    height: 270px;
    overflow: auto;
    box-sizing: border-box;
}
