.unsavedChangeBox {
    width: 500px;
}

.modalBody {
    padding: 15px 26px;
}

.warningMessage {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #262626;
}
