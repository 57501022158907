.textField {
    margin-bottom: 0px;
}

.apiCard {
    border: 1px solid #dbf0ff;
    box-sizing: border-box;
    border-radius: 8px;
    background: #eff8ff;
    padding: 58px 84px 58px;
    max-width: 317px;
}

.apiCard .apiCardPaddingSmall {
    padding: 34px 95px 31px 79px;
}

.apiCardDetail {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #154683;
    opacity: 0.5;
    text-align: center;
}

.backIcon {
    margin-bottom: 60px;
}

.toggleBtn {
    padding: 0px 0px 24px 0px;
}

.apiCardHeading {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #154683;
    margin-bottom: 22px;
    opacity: 0.5;
    text-align: center;
}

.integrationText_1 {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #111827;
    margin-bottom: 23px;
}

.integrationText_2 {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #002c41;
    margin-bottom: 72px;
}

.row1 {
    margin-bottom: 50px;
}

.row2 {
    margin-bottom: 98px;
}

.activetext {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #374151;
}

.switchBase {
    color: white !important;
}

.activeTrack {
    background-color: rgb(13, 198, 113) !important;
    opacity: 1;
}

.btnGroup {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.cancelButton {
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-transform: none;
    padding: 12px 24px;
}

.saveButton {
    color: #ffffff;
    background: #154683;
}

.saveButton:hover {
    background: #154683;
}

.hostawayContainer {
    margin-top: 16px;
}
