.workflowPreviewContainer {
    border: 1px solid #d1d5db;
    padding: 16px 10px;
    border-radius: 4px;
    background-color: #f9fafb;
}

.workflowConfigBox {
    margin-bottom: 20px;
}

.workflowConfigBox:last-child {
    margin-bottom: 0;
}

.workflowConfigBox .configHeading,
.configDesc {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #262626;
    margin-top: 4px;
}

.workflowConfigBox .configDesc {
    font-weight: 400;
}

.countryListText {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    margin-bottom: 0;
}

.moreWrapper {
    display: flex;
    column-gap: 3px;
    align-items: flex-end;
}

.moreWrapper span {
    color: #1570ef;
    font-size: 14px;
}
