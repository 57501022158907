.colorChooser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}
.title {
    font-family: var(--font-rubik);
    font-size: 16px;
    flex: 1;
}
.colorContainer {
    all: unset;
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid #dddddd;
    height: 38px;
    border-radius: 8px;
    overflow: hidden;
}
.colorPreviewBox {
    width: 48px;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid #dddddd;
}
.colorPickerInput {
    visibility: hidden;
}
.colorCode {
    font-size: 14px;
    font-family: var(--font-rubik);
    text-align: center;
    width: 100%;
    text-transform: lowercase;
    letter-spacing: 2%;
    padding-right: 8px;
}
