.container {
    padding: 10px 20px;
    background-color: #eff8ff;
    border-radius: 10px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.iconWrapper {
    height: 41px;
    width: 41px;
    border-radius: 100%;
    background-color: #d1e9ff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.buildingIcon {
    height: 24px;
    width: 24px;
    color: #1849a9;
}

.topText,
.bottomText,
.multipleListingsText {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    text-align: left;
    color: #154683;
}

.topText {
    font-size: 12px;
    line-height: 18px;
}

.bottomText {
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
}

.bottomText span {
    font-weight: 600px;
}
