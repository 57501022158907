.text {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #374151;
    padding: 10px 24px;
    overflow-wrap: break-word;
}

@media (min-width: 600px) {
    .container {
        width: 454px;
    }
}
