.wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.btn {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background-color: #fff;
    color: #262626;
    font-size: 12px;
    line-height: 18px;
}

.loader {
    width: 18px;
    height: 18px;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.loader path:first-child {
    stroke: #c9e1ff;
}

.loader path:last-child {
    stroke: #154683;
}

.text {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #262626;
}

@media (max-width: 600px) {
    .text {
        display: none;
    }

    .btn {
        padding: 10px 20px;
        border-radius: 2px;
        font-size: 16px;
    }
}
