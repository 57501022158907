.container {
    padding: 10px 20px;
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid #b2ddff;
}

.highlightItem {
    background-color: #eff8ff;
}

.radio span span,
.radio span span::before {
    height: 20px;
    width: 20px;
}

.radio[aria-selected='true'] span span {
    background-color: #154683;
}

.brandName {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1d2939;
}
