.loaderPageColor {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 9999;
    .loaderContainer {
      margin: 25vh 25vw;
      width: 50vw;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .loaderAlignCenter {
        display: 'table-cell';
        vertical-align: 'middle';
        .loaderLogoImg {
          animation-name: spin;
          animation-duration: 5000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear; 
          display: 'block';
          margin: 'auto';
        }
        .loaderText{
          color: #000000
        }
      }
    }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-360deg);
  }
}
