.dropdown {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.title {
    flex: 1;
    font-family: var(--font-rubik);
    font-size: 16px;
    font-weight: 400;
}

.dropdownContainer {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
}

.customWidth {
    div {
        width: 200px !important;
    }
}

.menuStyles {
    margin-top: 2px;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 8px;
}

@media (max-width: 600px) {
    .dropdown {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
    }

    .customWidth {
        div {
            width: 100% !important;
        }
    }

    .title {
        color: #374151;
        font-size: 14px;
        line-height: 24px;
    }
}
