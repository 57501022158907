.dashboardContainer {
    display: flex;
    height: 100vh;
}

.dashboardInnerContent {
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.renderScreenContainer {
    background: #f9fafb;
    padding: 24px 44px 74px;
    flex-grow: 1;
}

.renderScreenContainer .innerScreenContainer {
    max-width: 1280px;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    .renderScreenContainer {
        padding: 20px 16px 30px;
    }
}

.navigationContainer {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 12px 24px;
    color: #101828;
}

@media only screen and (max-width: 600px) {
    .navigationContainer {
        padding: 15.5px 16px;
    }
}

.companyInfo,
.userInfoSection {
    display: flex;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 600px) {
    .companyInfo {
        gap: 10px;
    }
}

.navigationContainer .commonIconStyle {
    width: 24px;
    height: 24px;
    color: #101828;
}

.companyInfo .menuToggleBtn {
    padding: 0;
}

.menuIcon,
.fileIcon {
    width: 24px;
    height: 24px;
    color: #101828;
}

@media only screen and (max-width: 600px) {
    .fileIcon {
        width: 20px;
        height: 20px;
    }
}

.companyInfo .companyName {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.96px;
}

@media only screen and (max-width: 600px) {
    .companyInfo .companyName {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #333;
    }
}

.userInfoSection .docLink {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: #101828;
}

@media only screen and (max-width: 600px) {
    .userInfoSection .docLink {
        font-size: 12px;
        line-height: 18px;
        gap: 4px;
    }
}

.divider {
    margin: 0 4px;
    background: #ddd;
}

.navigationContainer .userNameDropdown {
    padding: 7px 10px;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.59px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 0px 4px 0px #00000029;
    color: #101828;
}

.dropdownIcon {
    width: 18px;
    height: 18px;
    color: #101828;
    margin-right: 4px;
}
