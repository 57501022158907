.integrationHeader {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleBox {
    display: flex;
    align-items: center;
    gap: 20px;
}

.backIcon {
    width: 32px;
    height: 32px;
    color: #000;
}

.integrationHeading {
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.44px;
    color: #111827;
}

.integrationGuideLink {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #1d2939;
}

.shareIcon {
    width: 20px;
    height: 20px;
    color: #1d2939;
}

@media screen and (max-width: 600px) {
    .integrationHeader {
        border: none;
        padding: 0;
        background: none;
        align-items: flex-start;
        flex-direction: column;
    }

    .integrationHeading {
        font-size: 18px;
    }

    .integrationGuideLink {
        margin-left: 52px;
        font-size: 12px;
        font-weight: 400;
    }
}
