/* common styles */
.rootLabel,
.methodLabel,
.statusLabel {
    font-family: Rubik, sans-serif;
    color: #1d2939;
    font-weight: 400;
}

.methodIconWrapper,
.statusIconWrapper {
    height: 100%;
    width: auto;
    border-right: 2px solid #f79009;
    padding: 20px;
}

.rootNode {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #2e90fa;
    padding: 20px 50px;
    border-radius: 12.25px;
    background-color: #eff8ff;
    gap: 14px;
}

.rootLabel {
    font-size: 24px;
    line-height: 32px;
}

.shieldIcon {
    width: 54px;
    height: 53px;
    color: #2e90fa;
}

.methodNode {
    display: flex;
    align-items: center;
    border: 2px solid #f79009;
    background-color: #fffaeb;
    border-radius: 10px;
    min-width: 278px;
    position: relative;
}

.methodIconWrapper {
    border-right-color: #f79009;
}

.methodIcon {
    height: 40px;
    width: 40px;
    color: #f79009;
}

.methodLabel {
    padding: 25px;
    font-size: 20px;
    line-height: 30px;
}

.methodEdge {
    position: absolute;
    height: 2px;
    width: 40px;
    background-color: #9e77ed;
    transform: rotate(90deg);
    transform-origin: 0;
    bottom: -10px;
    left: 50%;
}

.statusNode {
    display: flex;
    align-items: center;
    border: 2px solid #12b76a;
    background-color: #ecfdf3;
    border-radius: 7px;
}

.statusIconWrapper {
    border-right-color: #12b76a;
}

.statusIcon {
    height: 30px;
    width: 30px;
    color: #12b76a;
}

.statusLabel {
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
}

.orEdgeLabel,
.andEdgeLabel {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 4px 12px;
}

.andEdgeLabel {
    background-color: #f4f3ff;
    border: 2px solid #7f56d9;
    border-radius: 16px;
    color: #5925dc;
}

.orEdgeLabel {
    background-color: #ecfdf3;
    border: 2px solid #039855;
    border-radius: 16px;
    color: #027a48;
}
