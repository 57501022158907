.layoutHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.layoutHeader .buildingIcon {
    width: 18px;
    height: 18px;
    color: #fff;
    margin-left: 4px;
}

.layoutContainer {
    background: #fff;
    margin-top: 16px;
    padding: 20px 35px;
    border-radius: 10px;
    overflow-y: hidden;
    height: auto;
}

.layoutContainer .sectionDivider {
    background-color: #d9d9d9;
    margin: 23px 0;
}

.layoutBody {
    display: grid;
    grid-template-columns: 3fr auto 1fr;
}

.layoutBody .sectionDividerVertical {
    background-color: #d1d5db;
    margin: 0 23px;
}

.listingsContainer {
    min-width: 300px;
    height: 85vh;
}

@media (max-width: 600px) {
    .layoutHeader {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 10px 24px;
        z-index: 999;
        background-color: #f9fafb;
        border-top: 1px solid #e5e7eb;
        flex: 1;
    }

    .layoutHeader .buildingIcon {
        display: none;
    }

    .configureBtn {
        padding: 8px 12px;
    }

    .configureBtn:disabled {
        background-color: #f2f4f7;
        color: #98a2b3;
    }
}
