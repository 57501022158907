.btn {
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
}

.btn:hover {
    background-color: #fff;
}

.indicatorImg {
    width: 26px;
    height: 26px;
    margin-right: 6px;
}

.chevronIcon {
    margin-left: 6px;
}

.chevronIcon svg path {
    fill: #262626;
}

.chevronIconOpen {
    transform: rotate(180deg);
}

.paper {
    margin-top: 5px;
    border-radius: 6px;
    border: 1px solid #eaecf0;
    background-color: #fff;

    ul {
        padding: 4px;

        li {
            justify-content: center;
        }
    }
}

.paper:has(.menuItem) {
    box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
}

.menuItem {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #565656;
}

@media (max-width: 600px) {
    .menuItem {
        min-height: auto;
    }
}
