.rightSection {
    width: 45%;
    height: 100%;
    position: absolute;
    border-left: 1px solid #d1d5db;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px 24px;
    background-color: #fff;
}

.previewHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    padding-top: 10px;
}

.infoIcon {
    width: 20px;
    height: 20px;
    color: #175cd3;
}

.previewHeading {
    color: #262626;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.previewOverride {
    padding: 0;
    border: none;
    background: #fff;
    margin-top: 10px;
}
