.container {
    width: 100%;
}

.contentWrapper {
    padding: 90px 26px;
}

.text {
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #262626;
    margin-bottom: 14px;
}
