.wrapper {
    padding: 0px 26px 10px;
}

.searchInput {
    border-radius: 8px;
}

.searchInput input,
.searchInput input::placeholder {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.searchInput input {
    padding: 12px 0px;
    color: #101828;
}

.searchInput input::placeholder {
    color: #98a2b3;
}

.searchIcon {
    width: 20px;
    height: 20px;
    color: #667085;
}

.hintText {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #6b7280;
    margin-top: 5px;
}

.tags {
    margin: 10px auto;
}

.tagWrapper {
    background-color: #eff8ff;
    gap: 10px;
    margin: 5px 5px 5px 0px;
}

.tagLabel {
    color: #175cd3;
}

.deleteIcon {
    color: #175cd3;
    height: 12px;
    width: 12px;
}
