.text {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #374151;
    padding: 24px;
}

@media (min-width: 601px) {
    .container {
        width: 374px;
    }
}
