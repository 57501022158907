.heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #111827;
}

.subHeading {
    font-size: 16px;
    line-height: 19px;
    color: #1f2937;
}

.headingSpace {
    padding-bottom: 35px;
    padding-top: 8px;
}

.card {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 135px;
    width: auto;
    position: relative;
    border-radius: 8px;
    filter: drop-shadow(2px 4px 6px black);
    transition: 1s;
}

.card:hover {
    transform: scale(1.1);
}

.cardHeading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 12px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    color: #ffffff;
}

.checkbox {
    position: absolute;
    right: 0;
}

.paginationWrapper {
    padding: 60px 0px 35px 0px;
}

.saveBtn {
    background: #154683;
    float: right;
    border-radius: 6px;
    padding: 10px 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: Rubik, sans-serif;
    text-transform: none;
}

.saveBtn:hover {
    background: #154683;
}

.saveBtn span {
    color: #ffffff;
}

.paginationBtn {
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
    text-transform: none;
    opacity: 1; /* default opacity */
}

.paginationBtn:disabled {
    opacity: 0.5;
}

.paginationBtn span {
    color: #374151;
}
