.contentWrapper {
    padding: 0px 26px;
    height: 100%;
}

.tableWrapper {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 200px;
    height: calc(100% - 200px);
    scrollbar-color: #ddd;
}

.tableWrapper::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
}

.tableWrapper::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 10px;
}

.tableWrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.indicatorWrapper {
    margin-top: 190px;
}
