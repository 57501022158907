.sidebarNav {
    padding-bottom: 140px;
    overflow: auto;
}

.navLinkContainer {
    text-decoration: none;
}

.navigationItem {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.collapseNavigationItem {
    padding: 10px;
    justify-content: center;
    flex-direction: column;
}

.activeListItem {
    background-color: #eff8ff;
    font-weight: 500;
    color: #154683;
}

.listItem {
    background-color: #fff;
    font-weight: 400;
    color: #101828;
}

.listItem:hover {
    background-color: #f2f4f7;
}

.navigationItem .listItemIcon {
    min-width: auto;
    color: inherit;
    width: 24px;
    height: 24px;
    display: inline;
}

.navigationItem .listItemLabel {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: inherit;
    line-height: 16.59px;
    color: inherit;
}

.navigationItem .mimiItemLabel {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    text-align: center;
}

/* Desktop sidebar styles 👇 */

.desktopDrawer {
    position: relative;
    border-right: 1px solid #ddd;
    width: 215px;
    transition: width 0.1s ease-in;
    scrollbar-width: none;
}

.desktopDrawer::-webkit-scrollbar {
    display: none;
}

.miniDesktopDrawer {
    width: 84px;
}

.desktopNavHeader {
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authenticateLogo {
    height: 20px;
    width: auto;
}

.desktopDrawer .sidebarCollapseBtn {
    padding: 16px 24px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: flex-end;
}

.desktopDrawer .sidebarExpandBtn {
    justify-content: center;
}

.sidebarCollapseBtn:hover {
    background-color: #f2f4f7;
    border-top: 1px solid #dddddd;
}

.collapseIcon {
    color: #98a2b3;
    width: 16px;
    height: 16px;
}

.collapseTooltip {
    background: #475467;
    color: #f9fafb;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

/* Tablet and Mobile sidebar styles 👇 */

.tabletSidebar {
    position: relative;
    border: none;
    width: 275px;
    background: #fff;
}

.tabletNavHeader {
    padding: 24px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.tabletNavHeader .toggleButton {
    width: 24px;
    height: 24px;
    border: 1px solid #475467;
    border-radius: 50%;
    padding: 2px;
    box-shadow: 0px 0px 4px 0px #00000040;
}

.toggleButton .leftArrow {
    width: 20px;
    height: 20px;
    color: #475467;
}

.divider {
    background-color: #ddd;
}

.userDetailsBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
}

.userNameBox {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.profileLogo {
    width: 15px;
    height: 15px;
    color: #667085;
}

.userNameLabel {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #667085;
}

.userNameLabel span {
    font-weight: 500;
}

.userOptionsBox .userMenuItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    color: #101828;
    text-decoration: none;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.userMenuItem:first-child {
    margin-bottom: 10px;
}
