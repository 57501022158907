.defaultBrandTag {
    background: #eff8ff;
    color: #175cd3;
}

.makeDefaultBrandTag {
    background: #f2f4f7;
    color: #344054;
    cursor: pointer;
}

.brandHeader {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.brandHeading {
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #262626;
    max-width: inherit;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    margin: 0;
}

.editBrandName {
    padding: 0;
}

.editIcon {
    width: 24px;
    height: 24px;
    color: #000;
}

.brandOptionsContainer {
    margin-top: 30px;
}

.brandOptionsHeader {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editOptionHeading {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1d2939;
}

.refreshButton {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #154683;
    border: none;
}

.refreshIcon {
    width: 18px;
    height: 18px;
}
