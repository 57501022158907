@import "asset/styles/breakpoints.scss";

.MuiGrid-root{
    .onboardingImageWrapper{
        margin-top:60px;
        // height:10vh
        
    }
    .onboardingContentWarpper{
    .onboardingBox{
        width: 486px;
    }
    }
}


@media only screen and (max-width:$break-point-medium) {
    .MuiGrid-root{
        .onboardingImageWrapper{
            margin-top: 44px;
        }
    }
 
}

@media only screen and (max-width:$break-point-mobile) {
    .MuiGrid-root{
        .onboardingImageWrapper{
            margin-top: 52px;
        }
    }
 
}

