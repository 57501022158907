.labelWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}
.labelOption {
    display: flex;
    align-items: center;
    gap: 5px;
}
.label {
    font-family: var(--font-rubik);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1d2939;
    text-wrap: nowrap;
}

.usOnlyLabel {
    font-family: var(--font-rubik);
    font-size: 14px;
    font-weight: 400;
    color: #667085;
    line-height: 24px;
    text-wrap: nowrap;
}
.infoButton {
    padding: 4px;
}
.infoIcon {
    color: #1d2939;
    height: 14px;
    width: 14px;
    cursor: pointer;
    flex-shrink: 0;
}

.divider {
    height: 1px;
    background-color: #dddddd;
}

.checkContainerWithAccordion {
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 0.6fr;
}

.responsiveWrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    background-color: #fff;
    border-radius: 12px;
    margin-top: 12px;
}

.tooltipCta {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    color: #111827;
    text-decoration: underline;
}
@media only screen and (max-width: 1280px) {
    .label {
        text-wrap: wrap;
    }
}
@media only screen and (max-width: 1024px) {
    .labelWrapper {
        width: 325px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        font-weight: 400;
    }
    .usOnlyLabel {
        color: #1d2939;
    }
    .tooltipCta {
        color: #fff;
        font-weight: 500;
    }
}

@media only screen and (max-width: 810px) {
    .labelWrapper {
        width: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .responsiveLayout {
        position: relative;
        margin-bottom: 40px;
    }
    .labelWrapper {
        width: 200px;
        align-items: flex-start;
    }
    .label {
        text-wrap: wrap;
    }
    .labelOption {
        pointer-events: none;
    }
}
