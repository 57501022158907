.container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    justify-content: center;
}

.text {
    color: white;
    font-weight: 500;
    font-size: 32px;
    line-height: 24px;
}

.countdown {
    color: white;
    font-weight: 500;
    font-size: 64px;
    line-height: 24px;
}

.backdrop {
    z-index: 1202;
    background-color: rgba(0, 0, 0, 0.8);
}
