.wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f4f6 url('../../../asset/img/onboarding-layout-bg.svg') no-repeat center/cover;
}

.container {
    position: relative;
    z-index: 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 272.36px;
        height: 107px;
        transform: skew(-15deg);
        z-index: -1;
    }
    &::before {
        background-color: #1570ef;
        top: -2.25rem;
        right: -7.5rem;
    }
    &::after {
        background-color: #0c1a68;
        bottom: -2.25rem;
        left: -7.5rem;
    }
}
.formWrapper {
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 40px;
    width: 512px;
    height: 564px;
    border-radius: 10px;
    box-shadow: 0px 4px 11px 0px #00000017;
}
.formWrapper .logo {
    width: auto;
    height: 20px;
}

@media only screen and (max-width: 820px) {
    .container {
        &::before,
        &::after {
            height: 67px;
            width: 170px;
        }

        &::before {
            top: -1.67rem;
            right: -4.56rem;
        }
        &::after {
            bottom: -1.67rem;
            left: -4.56rem;
        }
    }
}

@media only screen and (max-width: 768px) {
    .formWrapper {
        width: 478px;
    }
}

@media only screen and (max-width: 600px) {
    .wrapper {
        background: #f3f4f6;
    }

    .container {
        &::before,
        &::after {
            display: none;
        }
    }

    .formWrapper {
        height: 80vh;
        width: 86vw;
        padding: 40px 20px;
        box-sizing: border-box;
    }
}
