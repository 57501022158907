.card {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 135px;
    width: auto;
    position: relative;
    border-radius: 8px;
    filter: drop-shadow(2px 4px 6px black);
    transition: 1s;
}

.card:hover {
    transform: scale(1.1);
}

.checkbox {
    position: absolute;
    right: 0;
}

.cardHeading {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 12px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    color: #ffffff;
}
