.warningBanner {
    display: flex;
    gap: 1rem;
    margin-top: 16px;
    padding: 16px;
    border: 1px solid #f79009;
    border-radius: 2px;
    background-color: #fffaeb;
}

.warningIcon {
    color: rgba(245, 34, 45, 1);
    font-size: 21px;
    margin-top: 0.1rem;
}

.warningContent {
    width: 90%;
    max-width: 90%;
}

.warningHeading {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.warningInfo {
    font-family: var(--font-family);
    margin-top: 0.6rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5rem;
}

.warningConfirmBtn {
    font-family: var(--font-family);
    margin-top: 6px;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    border-radius: 2px;
    padding: 3px 12px;
    background-color: #f79009;
    border: 1px solid #f79009;
    text-transform: none;
    color: #fff;
}

.warningConfirmBtn:hover {
    background-color: transparent;
    color: #f79009;
}

.warningLinkText {
    color: #565656;
}
