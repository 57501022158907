.base {
    padding: 6px 16px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    font-family: 'Rubik', sans-serif;
    text-align: center;
    font-weight: 500;
    width: auto;
    height: auto;
    text-transform: none;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.base.md {
    padding: 8px 16px;
}

.base.lg {
    padding: 10px 16px;
}

.base.xl {
    padding: 12px 16px;
}

.base.xxl {
    padding: 16px;
}

.base.primary {
    background-color: #154683;
    color: #ffffff;
}

.primary:hover {
    background-color: #1f65bc;
}

.primary:active {
    background-color: #00377b;
}

.primary:disabled {
    background-color: #8ea2ba;
    color: #ffffff;
}

.base.secondary {
    background-color: #ffffff;
    color: #1d2939;
    border: 1px solid #bfbfbf;
}

.secondary:hover {
    background-color: #f9fafb;
    color: #262626;
}

.secondary:active {
    box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px #1018280d;
}

.secondary:disabled {
    background-color: #f2f4f7;
    color: #98a2b3;
    border: 1px solid #f3f4f6;
}
