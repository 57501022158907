.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    height: calc(100vh - 80px);
    margin-top: 10px;
    padding-top: 152px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lockIcon {
    width: 88px;
    height: 88px;
}

.lockIcon path {
    fill: #6b7280;
}

.description {
    font-family: Rubik, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #6b7280;
    max-width: 340px;
    margin: 20px auto;
}

.guestyLink {
    color: #154683;
    margin-top: 20px;
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

@media (max-width: 600px) {
    .description {
        font-size: 18px;
        line-height: 28px;
    }

    .primaryBtn {
        padding: 12px 35px;
    }
}
