.container {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
}

.hotelImg {
    width: 197px;
    height: 197px;
}

.arrowIcon {
    width: 18px;
    height: 18px;
}

.btn {
    margin: 20px auto;
}

.text {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000;
}
