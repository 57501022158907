.container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 13px;
}

.spinner {
    width: 28px;
    height: 28px;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.spinner path:first-child {
    stroke: #c9e1ff;
}

.spinner path:last-child {
    stroke: #154683;
}

.text {
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #154683;
}
