.wrapper {
    background-color: #ecfdf3;
    border-radius: 16px;
    padding: 4px 12px;
    width: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
}

.indicator {
    height: 26px;
    width: 26px;
}

.text {
    color: #027a48;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}
