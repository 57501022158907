/* Note: this file is required to override some styles in react-flow elements */
.react-flow__handle {
    opacity: 0;
    pointer-events: all;
}

.react-flow__controls-button {
    background-color: #000;
    height: 18px;
    width: 18px;
    border-radius: 2.15px;

    svg > path {
        fill: #fff;
    }
}

.react-flow__controls-button:hover {
    background-color: #000;
}

.react-flow__attribution {
    display: none;
}

.react-flow__panel {
    margin: 10px;
}
